.imgSwiper {
  :global {
    .swiper {
      width: 100%;
      height: 260px;
    }

    .swiper-slide {
      color: #fff;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 90%;
      height: 220px;
    }

    .swiper-pagination-bullet-active {
      background-color: #be7e4b !important;
    }
    .swiper-pagination-bullet {
      opacity: 1;
      background-color: #fff;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      margin: 0 6px !important;
    }
    .swiper-horizontal > .swiper-pagination-bullets,
    .swiper-pagination-bullets.swiper-pagination-horizontal {
      bottom: 0;
      width: 100%;
      height: 40px;
      line-height: 40px;
      border-radius: 10px;
      left: 0;
      right: 0;
      margin: auto;
    }
  }
  .item {
    position: relative;
    width: 100%;
    border-radius: 10px;
    overflow: hidden;
    img {
      width: 100%;
      height: 220px;
      object-fit: cover;
      border-radius: 10px;
    }
    .info {
      position: absolute;
      width: 100%;
      left: 0;
      bottom: 6px;
      right: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: rgba(0, 0, 0, 0.8);
      padding: 8px;
      box-sizing: border-box;
      span {
        font-size: 14px;
        font-weight: 400;
        display: -webkit-box;
        text-overflow: ellipsis;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
      }
      .play {
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        background: #601b9f;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        border: none;

        svg {
          width: 25px;
          height: 25px;
        }
      }
    }
  }
}
