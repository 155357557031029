//  历史记录
.history {
  display: flex;
  padding: 20px 10px;
  overflow-x: scroll;
  > a {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #071129;
    padding: 0 18px;
    width: 100px;
    height: 60px;
    border-radius: 10px;
    font-size: 16px;
    font-weight: 600;
    margin-right: 5px;
  }
  .playList {
    display: flex;
    a {
      margin-right: 5px;
    }
    img {
      width: 80px;
      height: 60px;
      object-fit: cover;
      border-radius: 10px;
    }
  }
}
.gameList {
  .recommend {
    position: relative;
    z-index: 10;
    margin: 0 10px;
    .recommendList {
      display: flex;
      justify-content: flex-start;
      overflow-x: scroll;
      a {
        position: relative;
        margin-right: 12px;
        img {
          width: 120px;
          height: 180px;
          object-fit: cover;
          border-radius: 10px;
        }
        p {
          position: absolute;
          bottom: 6px;
          width: 100%;
          height: 40px;
          line-height: 40px;
          background: rgba(0, 0, 0, 0.8);
          margin: 0;
          padding: 0 5px;
          box-sizing: border-box;
          font-size: 12px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          text-align: center;
        }
      }
    }
  }
}
.gameInfo {
  .home {
    display: flex;
    align-items: center;
    margin: 6px 0;
    position: fixed;
    top: 0;
    background: #000;
    padding: 5px 15px;
    border-radius: 10px;
    z-index: 99;
    font-size: 14px;
    img {
      display: block;
      width: 26px;
      margin-right: 5px;
    }
  }
  .loading {
    width: 40px;
    padding: 8px;
    margin: 20px auto;
    aspect-ratio: 1;
    border-radius: 50%;
    background: #9301f6;
    --_m:
      conic-gradient(#0000 10%, #000), linear-gradient(#000 0 0) content-box;
    -webkit-mask: var(--_m);
    mask: var(--_m);
    -webkit-mask-composite: source-out;
    mask-composite: subtract;
    animation: l3 1s infinite linear;
  }
  @keyframes l3 {
    to {
      transform: rotate(1turn);
    }
  }

  .playBox {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    width: 100%;
    height: 260px;
    padding: 0 6vw;
    box-sizing: border-box;
    img {
      display: block;
      width: 160px;
      height: 120px;
      border-radius: 10px;
      object-fit: contain;
      z-index: 1;
    }
    img:nth-child(2) {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      z-index: -1;
      // filter: blur(15px);
      /* 对背景图应用高斯模糊 */
    }
    .play {
      display: flex;
      align-items: center;
      height: 50px;
      line-height: 50px;
      background: #000;
      padding: 0 20px;
      border-radius: 20px;
      z-index: 1;
      img {
        width: 30px;
        height: 30px;
        margin: 0 5px;
      }
    }
  }
  .playBox::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(10px);
    border-radius: 0 0 10px 10px;
    overflow: hidden;
    /* 对背景应用高斯模糊 */
    z-index: 0;
  }
  .detail {
    padding: 0 10px;
    .titleBox {
      margin: 30px 0;
    }
    p {
      font-size: 14px;
      white-space: pre-wrap;
    }
    span {
      background: #502cba;
      padding: 5px 10px;
      border-radius: 5px;
      font-size: 14px;
    }
    .tips {
      font-size: 12px;
      color: #00fefa;
    }
    .category,
    .tag {
      margin: 30px 0;
      div {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
      }
    }
  }

  .hot {
    background: transparent;
    margin: 0 0 20px;
    padding: 0 10px;
    color: #fff;
  }
}

.hotList {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.recommend,
.hot {
  border-radius: 16px;
  margin: 0 10px;
  > a {
    display: flex;
    align-items: center;
    justify-content: space-between;
    span {
      color: #a003ff;
      font-size: 18px;
    }
  }
  .item {
    margin-bottom: 5px;
  }
}
.hot {
  margin: 0 10px 20px;
  .item {
    width: 30%;
    margin-bottom: 5px;
    background: #081229;
    color: #fff;
    border-radius: 10px;
    img {
      border-radius: 10px;
    }
    p {
      font-size: 12px;
      text-align: center;
      margin: 5px 0 20px;
      display: -webkit-box;
      text-overflow: ellipsis;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      overflow: hidden;
    }
  }
}

.page {
  padding: 0 10px;
  min-height: 500px;
  h1 {
    text-align: left;
    font-size: 18px;
  }
  .title {
    text-align: center;
    margin: 30px 0px;
    p {
      text-align: left;
      margin: 10px 0;
      font-size: 16px;
    }
  }

  .gameList {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .historyItem,
    a {
      position: relative;
      width: 47%;
      margin-bottom: 20px;
      img {
        border-radius: 10px;
      }
      p {
        display: -webkit-box;
        text-overflow: ellipsis;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
        font-size: 12px;
        text-align: center;
        position: absolute;
        bottom: 2px;
        left: 0;
        background: rgba(0, 0, 0, 0.8);
        width: 100%;
        height: 40px;
        line-height: 40px;
        margin: 0;
        padding: 0;
        border-radius: 0 0 10px 10px;
      }
      .removeButton {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        right: -5px;
        top: -8px;
        background: #8f00ff;
        color: #fff;
        font-size: 16px;
        z-index: 9;
        width: 22px;
        height: 22px;
        border-radius: 50%;
      }
    }
  }
}
.tags {
  padding: 10px 20px;
  .tagsList {
    p {
      font-size: 30px;
      font-weight: 600;
      margin: 15px 0;
    }
    a {
      display: flex;
      align-items: center;
      background: #26273e;
      width: 300px;
      height: 40px;
      padding: 0 15px;
      box-sizing: border-box;
      border-radius: 5px;
      margin-bottom: 15px;
    }
  }
}
@media screen and (min-width: 1000px) {
  .item {
    width: 18%;
  }
  .gameList {
    .recommend {
      .recommendList {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        padding: 0 10px;
        .item {
          position: relative;
          width: 18%;
          height: auto;
          padding: 0;
          margin: 0 20px 20px 0;
          img {
            width: 100%;
          }
          p {
            display: block;
            color: #fff;
          }
        }
      }
    }
    .recommend,
    .hot {
      margin-bottom: 10vh;
      .item {
        img {
          width: 100%;
        }
        p {
          display: block;
          color: #000;
        }
      }
    }
  }
}
