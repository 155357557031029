.desc {
  padding: 30px 10px;
  background: #26273e;
  color: #ccc;
  margin: 0 10px;
  border-radius: 10px;
  max-height: 500px;
  overflow-y: scroll;
  h1 {
    font-size: 24px;
    margin: 0 5px 30px;
    color: #fff;
    -webkit-text-stroke: 1px rgba(0, 0, 0, 0.5);
  }
  .descList {
    .descItem {
      margin-bottom: 20px;
    }
  }
  p {
    white-space: pre-wrap;
    font-size: 14px;
    color: #ddd;
  }
}
