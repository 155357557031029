.category {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin: 30px 0 0;
  padding: 0 10px;
  box-sizing: border-box;

  a {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 22%;
    height: 80px;
    background-color: #26273e;
    padding: 16px;
    border-radius: 10px;
    margin-bottom: 12px;
    border: 0.2px solid #792af5;
    span {
      margin-top: 5px;
    }
  }
}
