.category {
  padding: 0 10px;
  > a {
    display: flex;
    align-items: center;
    justify-content: space-between;
    span {
      color: #a003ff;
      font-size: 18px;
    }
  }
  .gameList {
    display: flex;
    justify-content: flex-start;
    overflow-x: scroll;

    a {
      margin-right: 10px;

      img {
        width: 120px;
        height: 90px;
        object-fit: cover;
        border-radius: 10px;
      }
    }
  }
}
